import React, { FunctionComponent } from "react";
import { Col, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { ImageInterface, PortableText } from "../../types/SanityTypes";
import sanityImageUrlBuilder from "../../utils/imageUrlBuilder";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import "./styles.scss";
// @ts-ignore
import BlockContent from "@sanity/block-content-to-react";

export interface ProductLandingHeroInterface {
    lineOne?: string;
    lineTwo?: string;
    lineThree?: string;
    image?: ImageInterface;
    heroImage?: ImageInterface;
    text?: PortableText | string;
    textDescription?: PortableText | string;
}
const ProductLandingHero: FunctionComponent<ProductLandingHeroInterface> = props => {
    const { lineOne, lineTwo, lineThree, image, text, heroImage, textDescription } = props;
    const { sanityId, sanityDataset } = useSiteMetadata();
    const urlBuilder = sanityImageUrlBuilder({
        projectId: sanityId,
        dataset: sanityDataset
    });

    return (
      <section className={`${textDescription ? "rx-landing--block" : ""} rx-landing--section rx-landing--hero`}>
        <Container fluid>
          <Row>
            <Col lg="6">
              <div className="rx-landing--hero-copy">
                <h1
                  className="rx-landing--hero-title"
                  aria-label={lineOne + " " + (lineTwo || "") + " " + (lineThree || "")}
                >
                  {lineOne} <span>{lineTwo}</span>
                  {lineThree}
                </h1>
                <div className="rx-landing--hero-desc">
                  <BlockContent blocks={text ?? textDescription} />
                </div>
              </div>
            </Col>
            <Col lg="6">
              {(image ?? heroImage) && (
                <picture className="placeholder">
                  <source
                    media={"(min-width: 768px)"}
                    srcSet={
                      urlBuilder
                        .image(image ?? (heroImage as ImageInterface))
                        .auto("format")
                        .quality(80)
                        .width(700)
                        .height(700)
                        .format("webp")
                        .url() || undefined
                    }
                  />
                  <img
                    src={
                      urlBuilder
                        .image(image ?? (heroImage as ImageInterface))
                        .auto("format")
                        .quality(80)
                        .width(483)
                        .height(483)
                        .format("webp")
                        .url() || undefined
                    }
                    alt={(image ?? (heroImage as ImageInterface)).alt as string}
                    className="img-fluid"
                  />
                </picture>
              )}
            </Col>
          </Row>
        </Container>
      </section>
    );
};

export default ProductLandingHero;
